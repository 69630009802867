.container {
    white-space: pre-wrap;
    margin: 0 16px 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid #ddd;
    padding-top: 16px;
}
.title {
    margin-bottom: 16px;
}
.title__container {
    margin-bottom: 16px;
}
.column {
    font-size: 14px;
    margin: 8px;
    padding: 8px;
    background-color: whitesmoke;
    word-break: break-all;
}