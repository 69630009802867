* {
    margin: 0;
    padding: 0;
}
.header {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
}
.switch {
    display: flex;
    align-items: center;
    margin: 0 16px 0 32px;
}
.switch__title {
    margin-right: 8px;
    font-weight: 500;
    color: #333333;
}
.editors__container {
    display: flex;
}
.control {
    display: flex;
}
.container {
    max-width: 1170px;
    margin: 0 auto;
}

.footer {
    height: 150px;
}
.stringify-btn-label {
    display: flex;
    align-items: center;
    justify-content: center;
}