html,
body {
  padding: 0;
  margin: 0;
  font-family: Source Sans Pro;
  color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input {
  font-family: Source Sans Pro;
}

* {
  box-sizing: border-box;
}