.uik-spinner_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

#uik-loading-bar-spinner.uik-spinner {
    position: absolute;
    z-index: 19 !important;
    animation: uik-loading-bar-spinner 600ms linear infinite;
}

#uik-loading-bar-spinner.uik-spinner .uik-spinner-icon {
    width: 16px;
    height: 16px;
    border-top-color: #a0a0a0;
    border-left-color: #a0a0a0;
    border-radius: 50%;
}

@keyframes uik-loading-bar-spinner {
    0%   { transform: rotate(0deg);   transform: rotate(0deg); }
    100% { transform: rotate(360deg); transform: rotate(360deg); }
}
